<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <Toolbar class="mb-4">
            <template #start>
              <Button
                id="voltar"
                label="Voltar"
                icon="pi pi-chevron-left"
                class="mt-2 mr-2 p-button-sm p-button-secondary"
                @click="$router.back()" />
            </template>
            <template #end>
              <Button
                v-if="exibirBotao() && modulo === 'servidor'"
                id="recusar"
                label="RECUSAR"
                icon="pi pi-times"
                class="mt-2 mr-2 p-button-danger pt-button-sm float-right"
                @click="confirmarReprovarProposta()" />
              <Button
                v-if="exibirBotao() && modulo !== 'servidor'"
                id="cancelar"
                label="CANCELAR"
                icon="pi pi-times"
                class="mt-2 mr-2 p-button-danger pt-button-sm float-right"
                @click="confirmarReprovarProposta()" />
              <Button
                v-if="exibirBotao() && proposta.tipo != 'RESERVA_CARTAO'"
                id="aprovar"
                icon="pi pi-check"
                class="mt-2 mr-2 p-button-success pt-button-sm float-right"
                label="ACEITAR"
                @click="confirmarAprovarProposta()" />

              <Button
                v-if="exibirBotao() && proposta.tipo == 'RESERVA_CARTAO'"
                id="aprovar"
                icon="pi pi-check"
                class="mt-2 mr-2 p-button-success pt-button-sm float-right"
                label="ACEITAR"
                @click="aprovarPropostaReservaCartao()" />
            </template>
          </Toolbar>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="rubrica">Rubrica</label>
              <InputText
                id="contrato"
                v-model="exibirNomeRubrica"
                type="text"
                disabled />
            </div>
            <div class="field col-3 md:col-3">
              <label for="contrato">Nº Contrato</label>
              <InputText
                id="contrato"
                v-model="proposta.contrato"
                type="text"
                disabled />
            </div>
            <div class="field col-3 md:col-3">
              <label for="agencia">Agência</label>
              <InputText
                id="agencia"
                v-model="proposta.agencia"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="status">Situação</label>
              <InputText
                id="status"
                v-model="proposta.statusProposta.nome"
                type="text"
                disabled />
            </div>
            <div class="field col-6 md:col-6">
              <label for="tipo">Tipo de Proposta</label>
              <InputText
                id="tipo"
                v-model="proposta.tipo"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="valorParcela">Valor Parcela</label>
              <InputText
                id="valorParcela"
                v-model="proposta.valorParcela"
                type="text"
                disabled />
            </div>
            <div class="field col-6 md:col-6">
              <label for="prazoTotal">Prazo</label>
              <InputText
                id="prazoTotal"
                v-model="proposta.prazoTotal"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="anoReferencia">Ano</label>
              <InputText
                id="anoReferencia"
                v-model="proposta.anoReferencia"
                type="text"
                disabled />
            </div>
            <div class="field col-6 md:col-6">
              <label for="mesReferencia">Mês</label>
              <InputText
                id="mesReferencia"
                v-model="proposta.mesReferencia"
                type="text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="cadastradoPor">Cadastrado Por</label>
              <InputText
                v-if="proposta.cadastradoPor !== null"
                id="cadastradoPor"
                v-model="proposta.cadastradoPor.nome"
                disabled />
              <InputText v-else id="cadastradoPor" disabled />
            </div>
            <div class="field col-6 md:col-6">
              <label for="cadastradoEm">Cadastrado Em</label>
              <InputText
                id="cadastradoEm"
                :value="proposta.auditMetadata.cadastradoEm | formatarData"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-12">
              <label for="observacao">Observação</label>
              <Textarea
                id="observacao"
                v-model="proposta.obs"
                :autoResize="true"
                disabled />
            </div>
          </div>
          <div v-if="exibirCampoConsignacoes()" class="p-fluid formgrid grid">
            <div class="field col-12 md:col-12">
              <label for="consignacao">Consignações</label>
              <Textarea
                id="consignacao"
                v-model="exibirConsignacoes"
                :autoResize="true"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid"></div>
        </template>
      </Card>
      <ModalConfirmarOperacao
        :exibir="exibirModalAprovar"
        :metodo="aprovarProposta"
        :msg="'Deseja aceitar a proposta?'"
        @mudarVisibilidade="
          exibirModalAprovar = $event
        "></ModalConfirmarOperacao>
      <ModalSenhaServidor
        :exibir="exibirModalAprovarSenha"
        :metodoSalvar="aprovarProposta"
        :tipo="true"
        :isNovaAverbacao="false"
        :msg="'Deseja aceitar a proposta?'"
        :labelConfirm1Button="'Sim'"
        :labelRejectButton="'Não'"
        @mudarVisibilidade="
          exibirModalAprovarSenha = $event
        "></ModalSenhaServidor>
      <ModalConfirmarOperacao
        :exibir="exibirModalReprovar"
        :metodo="reprovarProposta"
        :msg="
          modulo === 'servidor'
            ? 'Deseja recusar a proposta?'
            : 'Deseja cancelar a proposta?'
        "
        @mudarVisibilidade="
          exibirModalReprovar = $event
        "></ModalConfirmarOperacao>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import PropostaService from '@/service/PropostaService'
import CompraDividaService from '@/service/CompraDividaService'
import Proposta from '@/domain/Proposta.js'
import CompraDivida from '@/domain/CompraDivida.js'
import ModalConfirmarOperacao from '@/components/shared/modal/modalConfirmarOperacao.vue'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'
import { servidorStore } from '@/stores/servidor'

export default {
  components: {
    ModalConfirmarOperacao,
    ModalSenhaServidor,
  },

  setup() {
    const store = servidorStore()
    return { store }
  },

  data() {
    return {
      proposta: new Proposta(),
      compraDivida: new CompraDivida(),
      consignacoes: [],
      exibirConsignacoes: [],
      id: this.$route.params.idproposta,
      exibirModalAprovar: false,
      exibirModalAprovarSenha: false,
      exibirModalReprovar: false,
      cadastradoEm: '',
      modulo: '',
    }
  },

  computed: {
    exibirNomeRubrica() {
      return typeof this.proposta.rubrica != 'undefined'
        ? `${this.proposta.rubrica.rubrica} | ${this.proposta.rubrica.nome}`
        : ''
    },
    exibirStatus() {
      if (this.proposta.statusProposta != 'undefined') {
        if (this.proposta.statusProposta.nome === 'Aprovada') {
          return 'ACEITA PELO SERVIDOR'
        } else if (this.proposta.statusProposta.nome === 'Reprovada') {
          return 'RECUSADA PELO SERVIDOR'
        } else {
          return this.proposta.statusProposta.nome
        }
      } else return ''
    },
    retornarTipoProposta() {
      return this.tipoProposta()
    },
  },

  created() {
    this.service = new PropostaService(this.$http)
    this.compraDividaService = new CompraDividaService(this.$http)
  },

  mounted() {
    this.carregarProposta()
  },

  methods: {
    carregarProposta() {
      this.service
        .getPropostaById(this.id)
        .then((res) => {
          this.proposta = res
          if (
            this.proposta.tipo == 'RENEGOCIACAO' ||
            this.proposta.tipo == 'COMPRA_DE_DIVIDA' ||
            this.proposta.tipo == 'RESERVA_CARTAO'
          ) {
            this.carregarConsignacoes()
          }
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Ocorreu um erro. Tente novamente!',
            life: 10000,
          })
        })
    },

    formatarData(value) {
      if (!value) return ''
      else {
        this.data = moment(value).format('DD/MM/YYYY')
        return this.data
      }
    },

    moment: function () {
      return moment()
    },

    exibirCampoConsignacoes() {
      return (
        this.proposta.tipo == 'RENEGOCIACAO' ||
        this.proposta.tipo == 'COMPRA_DE_DIVIDA'
      )
    },

    carregarConsignacoes() {
      if (
        this.proposta.tipo == 'RENEGOCIACAO' ||
        this.proposta.tipo == 'COMPRA_DE_DIVIDA'
      ) {
        this.compraDividaService
          .getListaConsignacoesPorProposta(this.proposta.id)
          .then((res) => {
            this.compraDivida.consignacoes = res
            this.formatarExibicaoConsignacoes()
          })
      }
    },

    formatarExibicaoConsignacoes() {
      this.exibirConsignacoes = this.compraDivida.consignacoes
        .map((consignacao) => {
          let consinacaoFormatada = `Contrato: ${
            consignacao.numeroContrato
          }. Rubrica: ${
            typeof consignacao.rubrica != 'undefined'
              ? consignacao.rubrica.rubrica
              : ''
          } | ${
            typeof consignacao.rubrica != 'undefined'
              ? consignacao.rubrica.nome
              : ''
          }`

          return consinacaoFormatada
        })
        .join('\n')
    },

    exibirBotao() {
      if (
        typeof this.proposta.statusProposta != 'undefined' &&
        (this.proposta.tipo == 'RENEGOCIACAO' ||
          this.proposta.tipo == 'NOVA_AVERBACAO' ||
          this.proposta.tipo == 'RESERVA_CARTAO') &&
        !this.store.dados.bloqueada &&
        (this.$auth.hasRoleServidor() ||
          this.$auth.hasRoleAverbar() ||
          this.$auth.hasRoleConsignatariaAdmin() ||
          this.$auth.hasRoleConsignatariaUser() ||
          this.$auth.hasRoleGestaoAdmin() ||
          this.$auth.hasRoleGestao())
      ) {
        return this.proposta.statusProposta.nome == 'Pendente' &&
          (this.proposta.tipo == 'RENEGOCIACAO' ||
            this.proposta.tipo == 'NOVA_AVERBACAO' ||
            this.proposta.tipo == 'RESERVA_CARTAO')
          ? true
          : false
      } else {
        return false
      }
    },

    confirmarAprovarProposta() {
      this.exibirModalAprovarSenha = true
      this.exibirModalAprovar = false
    },

    aprovarProposta() {
      //this.atribuirDadosRenegociacao()
      this.service
        .aprovarProposta(this.proposta)
        .then((res) => {
          this.exibeToast('success', 'Proposta aprovada com sucesso!')
          this.carregarProposta()
          this.irParaConsignacaoDetalhe(res.data)
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
    },

    aprovarPropostaReservaCartao() {
      this.service
        .aprovarPropostaReservaCartao(this.proposta)
        .then((res) => {
          this.exibeToast('success', 'Proposta aprovada com sucesso!')
          this.carregarProposta()
          this.irParaReservaCartaoDetalhe(res.data)
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
    },

    atribuirDadosRenegociacao() {
      this.compraDivida.proposta = this.proposta
    },

    confirmarReprovarProposta() {
      this.exibirModalReprovar = true
    },

    reprovarProposta() {
      this.reprovar()
    },

    reprovar() {
      this.service
        .reprovarProposta(this.proposta)
        .then(() => {
          this.exibeToast('success', 'Proposta reprovada com sucesso!')
          this.carregarProposta()
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
    },

    tipoProposta() {
      if (this.proposta.tipo === 'NOVA_AVERBACAO') {
        return 'Nova Averbação'
      } else if (this.proposta.tipo === 'COMPRA_DE_DIVIDA') {
        return 'Compra de Dívida'
      } else if (this.proposta.tipo === 'RENEGOCIACAO') {
        return 'Renegociação'
      }
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },

    irParaConsignacaoDetalhe(data) {
      this.$router.push({
        name: 'consignacao-detalhe',
        params: { idconsig: data.id },
      })
    },

    irParaReservaCartaoDetalhe(data) {
      this.$router.push({
        name: 'proposta-detalhe',
        params: { idproposta: data.id },
      })
    },
  },
}
</script>
